import config from '../Config.json';
import React, { useState, useEffect, useContext  } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import $ from "jquery";

import DataTable from 'datatables.net-react';
// import 'datatables.net-responsive-dt';
import DT from 'datatables.net-dt';
import { useMemo } from 'react';
import { MRT_Table, MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import ChampRecherche from "./ChampRecherche.js";

// --------------------------------------------- [ STORE ]
//  _store : writing / store : reading
import { snapshot, useSnapshot } from 'valtio'
import { _store } from './_Context.js';

// --------------------------------------------- [ UTILS ]
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tools from "./Tools" ;

DataTable.use(DT);


const Dossiers = () => {
  
  const store = useSnapshot(_store);  
  
  window.store = _store;
  window.tools = Tools;
  

  // const _App = useSnapshot(_storeApp); 
  // const [datas, setDatas] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [agestiaTable, setAgestiaTable] = useState(null);
  const navigate = useNavigate(); // Utilisé pour rediriger l'utilisateur
  let a = null;

  const data = [
    {
      name: {
        firstName: 'Zachary',
        lastName: 'Davis',
      },
      address: '261 Battle Ford',
      city: 'Columbus',
      state: 'Ohio',
    },
    {
      name: {
        firstName: 'Robert',
        lastName: 'Smith',
      },
      address: '566 Brakus Inlet',
      city: 'Westerville',
      state: 'West Virginia',
    },
    {
      name: {
        firstName: 'Kevin',
        lastName: 'Yan',
      },
      address: '7777 Kuhic Knoll',
      city: 'South Linda',
      state: 'West Virginia',
    },
    {
      name: {
        firstName: 'John',
        lastName: 'Upton',
      },
      address: '722 Emie Stream',
      city: 'Huntington',
      state: 'Washington',
    },
    {
      name: {
        firstName: 'Nathan',
        lastName: 'Harris',
      },
      address: '1 Kuhic Knoll',
      city: 'Ohiowa',
      state: 'Nebraska',
    },
  ];


  /************************************** MantineReactTable */
  //  should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name.firstName', //access nested data with dot notation
        header: 'First Name',
      },
      {
        accessorKey: 'name.lastName',
        header: 'Last Name',
      },
      {
        accessorKey: 'address', //normal accessorKey
        header: 'Address',
      },
      {
        accessorKey: 'city',
        header: 'City',
      },
      {
        accessorKey: 'state',
        header: 'State',
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.info(row.original, event);
        navigate(`/dossier/${row.id}`); // Redirige vers la page dashboard (ou toute autre page)
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
  });
/************************************** MantineReactTable */


useEffect(() => {
    
    // Simulate a load event
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 2000);
    
    const apiUrl = `https://api.agestia.fr/dossiers`;
    const token = getCookie('agestia_auth_token');

    // if(!_Auth.isAuthenticated) navigate('/');    


    function getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    const fetchDossiers = async () => {
      // Appel à l'API pour vérifier les identifiants
      // notify();
      try {
        const response = await fetch(apiUrl, {
          method: 'GET', // Même si on envoie les données dans l'URL, on utilise POST
          headers: {
            'Content-Type'  : `application/json`,
            'Authorization' : `Bearer ${token}`
          },
        });
        
        const data = await response.json();
        // Vérifie si l'authentification est réussie
        if (data.result === "SUCCESS") {

          // setDatas( data.results );
          _store.app.dossiers = data.results; 
          window.store = Tools.unproxify(store);

          /************************************** Tabulator */
          // table.replaceData(data.results); 

          /************************************** DataTables */
          a.clear();  // jquery datatables
          a.rows.add(data.results).draw();  // jquery datatables
          a.rows().to$().addClass('hover no-select');


          // setAgestiaTable(a);
          window.agestiaTable = a;
          // console.log(a);

        } else {
          // En cas d'échec, afficher la raison
          setErrorMessage(data.reason);
        }
      } catch (error) {
        console.error('Erreur lors de la requête:', error);
        setErrorMessage('Une erreur est survenue. Veuillez réessayer.');
      }
    };
    

    /************************************** Tabulator */
    //create Tabulator on DOM element with id "example-table"
    // var table = new Tabulator("#agestia-table", {
    //   height: '100%', // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
    //   // data:tabledata, //assign data to table
    //   // data:datas, //assign data to table
    //   layout:"fitColumns", //fit columns to width of table (optional)
    //   pagination:true, //enable pagination
    //   paginationSize:10, //optional parameter to request a certain number of rows per page
    //   // responsiveLayout:true, // enable responsive layouts
    //   columns:[ //Define Table Columns
    //     // {title:"Name", field:"name", width:150},
    //     // {title:"Age", field:"age", hozAlign:"left", formatter:"progress"},
    //     // {title:"Favourite Color", field:"col"},
    //     // {title:"Date Of Birth", field:"dob", sorter:"date", hozAlign:"center"},
    //     // {title:"Id", field:"id", width:60},
    //     {title:"Nom", field:"nom"},
    //     {title:"Ref", field:"reference"},
    //     {title:"Type", field:"typeaffaire", sorter:"date", hozAlign:"center"},            
    //   ],
    // });

    // //trigger an alert message when the row is clicked
    // table.on("rowClick", function(e, row){ 
    // alert("Row " + row.getData().id + " Clicked!!!!");
    // });
    /************************************** Tabulator */


    
    /************************************** DataTables */
    let colResize_options = {
      isEnabled: true,
    };

    if($.fn.dataTable.isDataTable('#agestiaTable')){
      a = $('#agestiaTable').DataTable();
    }else{
      a = $('#agestiaTable')
      .on('draw.dt', function () {
      console.log('DT Drawing');
      // Here, you can show a loader or update a message container
      // $("#MessageContainer").html('<span class="loading loading-infinity loading-sm"></span>');
    })
    .on('init.dt', function () {
      console.log('DT Init');
      // Hide the loader or update the message container when the table is loaded
      $(".agestiaSearch").attr("placeholder", "Recherche ...");
      $('.dt-paging').addClass('p-3');

    })     
    .DataTable({
      responsive: true,
      dom: 'rtip', // lfrtip
      processing: true,
      // colResize: colResize_options,
      columns: [
        { "data": "id" },
        { "data": "nom" },
        { "data": "reference" },
        { "data": "typeaffaire" },
      ],
      columnDefs: [
        {
          target: 0,
          visible: false,
          searchable: false
        }
      ],      
      createdRow: function (row, data, index) {
        $(row).attr('data-index', index);

        $("#agestiaTable tbody").on('click','tr',function(e){
          e.stopPropagation()                       
          let rowID = $(this).data('index');      
          $(this).addClass('magictime puffOut');
          console.log(data['id']);
          navigate(`/dossier/${rowID}`);
        });
      },
      language: { 
        // loadingRecords: '<span class="loading loading-infinity loading-sm"></span>',
        infoEmpty: 'Pas de données',
        emptyTable: '<span class="loading loading-infinity loading-lg">Données en cours de chargement</span>'
      },
    });
  }
  /************************************** DataTables */

  fetchDossiers();

  }, []);


  return (


<div id="swup" className="init container mx-auto mt-16 p-2" >

  <ToastContainer position="top-right" />

  <div className="font-bold text-sm breadcrumbs flex">
    <ul className="mx-auto">      
      <li><a><i className="iconoir-breadcrumb iconoir-folder"></i>&nbsp;  DOSSIERS</a></li> 
    </ul>
  </div>

  {/* <?php include ("template_parts/field-recherche.php"); ?> */}
  <span id="search-type" data-type="dossiers"></span>
  {/* <ChampRecherche type="dossier"/> */}

  <div className="container mx-auto">
      {/* Tabulator */}
    {/* <h2>Tabulator</h2>
    <div id="agestia-table"></div> */}

    {/* <MantineReactTable table={data} />  */}

    {/* DataTables */}
    {/* <h3>DataTables</h3> */}
    <table id="agestiaTable" className="table stripe hover content-main">
      <thead>
      <tr className="hover" data-order data-visible="false">
          <th>id</th>
          <th>Nom</th>
          <th>Référence</th>
          <th>Type Affaire</th>
        </tr>
      </thead>
      {/* <tbody>
      {
          datas && datas.map( (dossier, index) =>
            <tr key={dossier.id}>
              <td>{dossier.id}</td>
              <td>{dossier.nom}</td>
              <td>{dossier.reference}</td>
              <td>{dossier.typeaffaire}</td>
            </tr>
          )
        }
      </tbody> */}
    </table>

  </div>

</div>



  );
};

export default Dossiers;
