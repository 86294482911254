import config from '../Config.json';
import React, { /*useState, useContext,*/ useEffect } from "react";
import { useNavigate } from "react-router-dom";

// --------------------------------------------- [ ICONS ]
import {
  EuroSquare,
  Clock,
  Calendar,
  Mail,
  Folder,
  /*Book*/
  UserCircle,
  Microphone,
} from "iconoir-react"; // iconoir lib

// --------------------------------------------- [ STORE ]
import { proxy, useSnapshot } from "valtio";
import { _store } from "./_Context.js"; // _store for write + store for reading

// --------------------------------------------- [ UTILS ]
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tools from "./Tools" ;


const Dashboard = () => {
  
  const navigate = useNavigate(); // Utilisé pour rediriger l'utilisateur
  const store = useSnapshot(_store);  

  window.store = _store;
  window.tools = Tools;

  useEffect(() => {
    _store.auth.isAuthenticated = true;
  }, []);


  return (
    <div id="swup">
    <div id="reactapp" className=" init container mx-auto mt-16 p-2">
      <div className="hidden loading-infinity loading-lg"></div>

      <div className="init overflow-x-auto p-2 flex flex-wrap flex-row flex-btn-row">
        <div className="w-full -bg-neutral -text-neutral-content py-4">
          <div className=" items-start text-left">
            <p>
              Bonjour{" "}
              <span className="init label-username pb-3">
                <b>{store.auth.current_user.nom}</b>,
                <span className="skeleton h-4 w-28"></span>
              </span>
            </p>
          </div>
        </div>

        {/* {JSON.stringify(store.auth)} */}

        <div className=" flex w-full md:flex flex-wrap flex-row">
          <div className="p-2 sm:p-5 grow w-1/2 sm:w-1/2 md:w-1/4">
            <a onClick={() => navigate("/dossiers")}
              // href="/dossiers"
              type="button"
              id="computer-desktop-btn"
              aria-label="computer-desktop"
              aria-haspopup="true"
              aria-controls="computer-desktop"
              aria-expanded="false"
              className="relative -content-between inset-0 flex flex-row h-24 w-100 btn btn-neutral"
            >
              <span className="transition-transform duration-500 ease-in-out stroke-neutral-content">
                {/* <i className="iconoir-icon iconoir-folder text-xl"></i> */}
                <Folder height={16} width={16} />
              </span>
              <span className="font-bold mt-1">DOSSIERS</span>
            </a>
          </div>

          <div className="p-2 sm:p-5 grow w-1/2 sm:w-1/2 md:w-1/4">
            <a onClick={() => navigate("/contacts")}
              // href="/contacts"
              type="button"
              id="computer-desktop-btn"
              aria-label="computer-desktop"
              aria-haspopup="true"
              aria-controls="computer-desktop"
              aria-expanded="false"
              className="relative -content-between inset-0 flex flex-row h-24 w-100 btn btn-neutral"
            >
              <span className="transition-transform duration-500 ease-in-out stroke-neutral-content">
                {/* <i className="iconoir-icon iconoir-user-circle text-xl"></i> */}
                <UserCircle height={16} width={16} />
              </span>
              <span className="font-bold mt-1">CONTACTS</span>
            </a>
          </div>

          <div className="p-2 sm:p-5 grow w-1/3 sm:w-1/3 md:w-1/4">
              <a
                href="/facturation.php"
                type="button"
                id="computer-desktop-btn"
                aria-label="computer-desktop"
                aria-haspopup="true"
                aria-controls="computer-desktop"
                aria-expanded="false"
                className="-content-between px-1 inset-0 flex flex-row h-12 w-100 btn btn-primary"
              >
                <span className="transition-transform duration-500 ease-in-out stroke-neutral-content">
                  <EuroSquare height={16} width={16} />
                  {/* <i className="iconoir-icon iconoir-euro-square"></i> */}
                </span>
                <span className="font-bold -mt-1 text-xs">Facture</span>
              </a>
            </div>

            <div className="p-2 sm:p-5 grow w-1/3 sm:w-1/3 md:w-1/4">
              <a
                href="https://transcription.agestia.fr/?key=demokey"
                type="button"
                id="computer-desktop-btn"
                aria-label="computer-desktop"
                aria-haspopup="true"
                aria-controls="computer-desktop"
                aria-expanded="false"
                className="-content-between px-1 inset-0 flex flex-row h-12 w-100 btn btn-primary"
              >
                <span className="transition-transform duration-500 ease-in-out stroke-neutral-content">
                  <Microphone height={16} width={16} />
                  {/* <i className="iconoir-icon iconoir-microphone"></i> */}
                </span>
                <span className="font-bold -mt-1 text-xs">Transcr.</span>
              </a>
            </div>

            <div className="p-2 sm:p-5 grow w-1/3 sm:w-1/3 md:w-1/4">
              <a
                href="/dossiers.php"
                type="button"
                id="computer-desktop-btn"
                aria-label="computer-desktop"
                aria-haspopup="true"
                aria-controls="computer-desktop"
                aria-expanded="false"
                className="-content-between px-1 inset-0 flex flex-row h-12 w-100 btn btn-primary"
              >
                <span className="transition-transform duration-500 ease-in-out stroke-neutral-content">
                  <Clock height={16} width={16} />
                  {/* <i className="iconoir-icon iconoir-clock text-sm"></i> */}
                </span>
                <span className="font-bold -mt-1 text-xs">Tâches</span>
              </a>
            </div>

            <div className="p-2 sm:p-5 grow w-1/2 sm:w-1/2 md:w-1/4">
              <a
                href="/calendar.php"
                type="button"
                id="computer-desktop-btn"
                aria-label="computer-desktop"
                aria-haspopup="true"
                aria-controls="computer-desktop"
                aria-expanded="false"
                className="-content-between px-1 inset-0 flex flex-row h-12 w-100 btn btn-accent-"
              >
                <span className="transition-transform duration-500 ease-in-out stroke-neutral-content">
                  <Calendar height={16} width={16} />
                  {/* <i className="iconoir-icon iconoir-calendar text-sm"></i> */}
                </span>
                <span className="font-bold -mt-1 text-xs">Agenda</span>
              </a>
            </div>

            <div className="p-2 sm:p-5 grow w-1/2 sm:w-1/2 md:w-1/4">
              <a
                href="/dossiers.php"
                type="button"
                id="computer-desktop-btn"
                aria-label="computer-desktop"
                aria-haspopup="true"
                aria-controls="computer-desktop"
                aria-expanded="false"
                className="-content-between px-1 inset-0 flex flex-row h-12 w-100 btn btn-accent-"
              >
                <span className="transition-transform duration-500 ease-in-out stroke-neutral-content">
                  <Mail height={16} width={16} />
                  <i className="iconoir-icon iconoir-mail text-sm"></i>
                </span>
                <span className="font-bold -mt-1 text-xs">Email</span>
              </a>
            </div>

            <div className="p-2 sm:p-5 grow w-1/2 sm:w-1/2 md:w-1/4">
              <a
                href="/calendar2.php"
                type="button"
                id="computer-desktop-btn"
                aria-label="computer-desktop"
                aria-haspopup="true"
                aria-controls="computer-desktop"
                aria-expanded="false"
                className="-content-between px-1 inset-0 flex flex-row h-12 w-100 btn btn-accent-"
              >
                <span className="transition-transform duration-500 ease-in-out stroke-neutral-content">
                  <i className="iconoir-icon iconoir-calendar text-sm"></i>
                </span>
                <span className="font-bold -mt-1 text-xs">Agenda test </span>
              </a>
            </div>

            <div className="container mx-auto py-3">
              <div
                id="search"
                className="flex items-center justify-center"
              ></div>
            </div>
          </div>

          <div className="container flex flex-wrap mx-auto p-2">
            <div className="card sm:p-5 grow w-full sm:w-1/2 md:w-1/2 p-2 bg-base-200 my-2">
              <div className="divider divider-primary">Planning du Jour</div>
              <div className="overflow-x-auto  pb-5">
                <table className="table table-planning">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Date</th>
                      <th>Libellé</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>

            <div className="card sm:p-5 grow w-full sm:w-1/2 md:w-1/2 p-2 bg-base-200 my-2">
              <div className="divider divider-primary">
                Dernière(s) Facture(s){" "}
              </div>
              <div className="overflow-x-auto pb-5">
                <table className="table table-factures">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Job</th>
                      <th>Favorite Color</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
