import config from '../Config.json';
import { useState, useRef } from 'react';
import { Button, Modal } from 'react-daisyui';
import { _store } from "./_Context.js"; // _store for write + store for reading

const ModalDisconnect = ( props ) => {

  const ref = useRef(null);
  const {primaryfn, /*open, cancelFn, closeIcon, titleContent, content */} = props;
  // const [isModalOpen, setModalOpen] = useState(isOpen);

  // useEffect(() => {
  //   setModalOpen(isOpen);
  // }, [isOpen]);
  
  const handleShow = (() => {
    // ref.current?.showModal();
    _store.auth.isDeconnecting = !_store.auth.isDeconnecting;
  });
  // }, [ref]);

  // const args={}

  return ( 
  <div >
    {/* <Button onClick={handleShow}>Open Modal</Button> */}
    <Modal {...props} ref={ref}>
      <Modal.Header className="font-bold">Agestia</Modal.Header>
      <Modal.Body>
        {props.content}
      </Modal.Body>
      <Modal.Actions>
        <form method="dialog">
          <Button size="md" color="primary" className="btn mx-1 px-2" onClick={primaryfn}>Confirmer</Button>
          <Button size="md" color="" className="btn mx-1 px-2" onClick={handleShow}>Annuler</Button>
        </form>
      </Modal.Actions>
    </Modal>
  </div>
  )
}


export default ModalDisconnect;