// eslint-disable-next-line react-hooks/exhaustive-deps

//#region Users.js
//#endregion

import config from '../Config.json';
import React, { useState, useEffect, useContext  } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileCircle } from 'iconoir-react'; // iconoir lib
import { motion, stagger } from "motion/react";

// --------------------------------------------- [ STORE ]
import { proxy, subscribe, useSnapshot } from 'valtio'
import { _store } from './_Context.js'; // _store for write + store for reading
import { useStoreState, useStoreActions } from 'easy-peasy';

// --------------------------------------------- [ UTILS ]
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tools from "./Tools" ;


const Users = () => {
  
  const navigate = useNavigate(); // Utilisé pour rediriger l'utilisateur
  const store = useSnapshot(_store);  
  window.store = Tools.unproxify(store);


  // const appstore = useStoreState((state) => state);
  // const setValue = useStoreActions((actions) => actions.setValue);

  const [currentUser, setCurrentUser] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // const [isLoading, setIsLoading] = useState(true);
  const[ users, setUsers ] = useState([]);    
  
  
  // let t ="";
    // const notify = Tools.notify(t||"Agestia !");
  // function notify (t) { toast.success(t||"Bienvenu") };

  // const notify = (t,s="i") => Tools.notify(t,s);
  // window.notify = notify;


  function affectUser(u_id) {
    let current_user;
    // setCurrentUser(u_id);
    Tools.setCookie('agestia_logged', true)
    current_user = users.filter( (u) => u.id === u_id );
    _store.auth.current_user_id = u_id;
    _store.auth.current_user = current_user[0];
    _store.auth.isAuthenticated = true;
    
    Tools.getMissiontypes();
    Tools.getCivilites();

    // setValue(['auth.current_user_id', u_id]);
    // setValue(['auth.current_user', current_user[0]]);


    // notify();
    navigate('/dashboard');
  };
  
  
  
  useEffect(() => {
    
    // Simulate a load event
    // setTimeout(() => {
      //   setIsLoading(false);
      // }, 2000);
      
      const apiUrl = `https://api.agestia.fr/utilisateurs`;
      const token = Tools.getCookie('agestia_auth_token');
      
      // if(!isAuthenticated) navigate('/');
      // if(!store.auth.isAuthenticated) navigate('/');
      
      const fetchData = async () => {
        // Appel à l'API pour vérifier les identifiants
      try {
        const response = await fetch(apiUrl, {
          method: 'GET', // Même si on envoie les données dans l'URL, on utilise POST
          headers: {
            'Content-Type'  : `application/json`,
            'Authorization' : `Bearer ${token}`
          },
        });
        
        const data = await response.json();
        
        // Vérifie si l'authentification est réussie
        if (data.result === "SUCCESS") {

          if(config.DEBUG)
            console.log(data) ;

          setUsers( data.results );
          // setValue(['auth.users', data.results]);
          _store.auth.users = data.results;        
        } else {
          // En cas d'échec, afficher la raison
          setErrorMessage(data.reason);
        }
      } catch (error) {
        console.error('Erreur lors de la requête:', error);
        setErrorMessage('Une erreur est survenue. Veuillez réessayer.');
      }
    };
    fetchData();

  }, []);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  return (

<div id="swup" className=" init hero min-h-screen bg-base-200 transition-fade">
  <div className="hero-content flex-col lg:flex-row-reverse">
    <div className="text-center m-6 lg:text-left">
      <h1 className="text-4xl font-bold">Utilisateur(s)</h1>
      <p className="py-6">Choix de l'Utilisateur</p>
    </div>
    <div className="card shrink-0 w-full max-w-sm border-base-200 border-solid border-1 shadow-xl bg-base-100">
      <motion.form layout transition={{ staggerChildren: 0.5 }} className="container card-body">
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          
        <div className="grid grid-cols-3 gap-4 content-main">
        {users && users.map( (user, index) =>
          <motion.div key={index} initial={{ opacity: 0, transform: "translateX(-30px)" }} animate={{ opacity: 1, transform: "translateX(0px)" }}>   
            <button onClick={() => { affectUser(user.id)} } data-idx={index} data-id={user.id} className=" flex agestia-user-idx  shadow-lg btn btn-neutral p-3 h-24">
              <ProfileCircle height={16} width={16} />{user.nom}
            </button>
          </motion.div>                     
        )}
        <div className="border border-dashed shadow-sm h-24"></div>
        </div>
        <div className="form-control mt-6">
          <button className="btn btn-primary">CONNECTEZ-VOUS</button>
        </div>

      </motion.form>
      {/* <button className="btn" onClick={notify}>Notify!</button> */}
      <ToastContainer position="bottom-right" />

        {/* {JSON.stringify(appstore.auth)} */}

    </div>
  </div>
</div>

  );
};

export default Users;
