//#region Head.js
//#endregion
import config from '../Config.json';
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import $ from "jquery";

import ModalDisconnect from "./Modal";
import { Drawer } from './Drawer.js'; // Un autre composant 
import { proxy, useSnapshot } from "valtio";
import { _store } from "./_Context.js"; // _store for write + store for reading

// --------------------------------------------- [ UTILS ]
import { LogOut } from 'iconoir-react'; // iconoir lib
import { ToastContainer, toast } from 'react-toastify';
import Tools from "./Tools" ;

const Head = () => {  

  const [IsLoggedIn, setIsLoggedIn] = useState();
  const navigate = useNavigate(); // Utilisé pour rediriger l'utilisateur  
  const store = useSnapshot(_store);  
  const ref = useRef(null);
  
  window.store = _store;
  window.tools = Tools;

  const handleShowDisconnect = useCallback(() => {
    // ref.current?.showModal();
    _store.auth.isDeconnecting = !_store.auth.isDeconnecting;
  }, [ref]);

  const fDisconnect = () => {
    _store.auth.isDeconnecting = !_store.auth.isDeconnecting;
    _store.auth.isAuthenticated = false;
    // $('.drawer-toggle').trigger('click');
    config.DEBUG && console.log("disconnect");
    
    navigate("/");
  };

  // function getCookie(cname) {
  //   let name = cname + "=";
  //   let decodedCookie = decodeURIComponent(document.cookie);
  //   let ca = decodedCookie.split(";");
  //   for (let i = 0; i < ca.length; i++) {
  //     let c = ca[i];
  //     while (c.charAt(0) === " ") {
  //       c = c.substring(1);
  //     }
  //     if (c.indexOf(name) === 0) {
  //       return c.substring(name.length, c.length);
  //     }
  //   }
  //   return "";
  // }

  useEffect(() => {

    // console.log(store);
    if(!store.auth.isAuthenticated) navigate('/');    
    setIsLoggedIn( Tools.getCookie('agestia_logged') );
    
  }, []);    
  

  
return (

  <div>
    <ToastContainer />
    {/* <dialog id="modal_deconx" className="modal">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Hello!</h3>
        <p className="py-4">Etes-vous sur de vouloir vous déconnecter ?</p>
        <div className="modal-action">
          <form method="dialog">
            <button className="btn btn-default btn-confirm btn-confirm-disconnect">Confirmer</button>
            <button className="btn btn-accent btn-close">Annuler</button>
          </form>
        </div>
      </div>
    </dialog> */}

    <ModalDisconnect open={store.auth.isDeconnecting} primaryfn={() => fDisconnect()} content="Voulez-vous vous déconnecter ?"/> 

    <div className="drawer">
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" /> 
      <div className="drawer-content flex flex-col" /*style="font-size : 0.875rem" */>

        <header className="w-full navbar shadow-md fixed bg-base-100 z-10">
          <div className="navbar-start">
            <div className="flex-none lg:hidden">
              <label htmlFor="my-drawer-3" aria-label="open sidebar" className="btn btn-square btn-ghost mr-2">
              <svg id="toggleSidebarMobileHamburger" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
              </label>
            </div> 
            
            <a onClick={() => navigate("dashboard")} className="text-xl init navbar-logo font-extrabold flex items-center justify-center lg:ml-2.5">
              <img src="/img/agestia.png" className="h-6 mr-2" alt="Agestia Logo"/>
              <span className="self-center whitespace-nowrap uppercase">Agestia</span>
            </a>   
          </div>

          <div className="navbar-center hidden lg:flex">
              <ul className="menu menu-horizontal px-1">
                <li>
                  <Link to="/dashboard" className="py-0 text-lg hover:bg-gray-600 hover:text-white" >Accueil</Link>
                  {/* <a
                    className="py-0 text-lg hover:bg-gray-600 hover:text-white"
                    href="/Dashboard"
                  >
                    Accueil
                  </a> */}
                </li>
                <li>
                <Link to="/dossiers" className="py-0 text-lg hover:bg-gray-600 hover:text-white" >Dossiers</Link>
                  {/* <a
                    className="py-0 text-lg hover:bg-gray-600 hover:text-white"
                    href="Dossiers"
                  >
                    Dossiers
                  </a> */}
                </li>
                <li>
                <Link to="/contacts" className="py-0 text-lg hover:bg-gray-600 hover:text-white" >Contacts</Link>
                  {/* <a
                    className="py-0 text-lg hover:bg-gray-600 hover:text-white"
                    href="Contacts"
                  >
                    Contacts
                  </a> */}
                </li>
                <li>
                <Link to="/Facturation" className="py-0 text-lg hover:bg-gray-600 hover:text-white" >Facturation</Link>
                  {/* <a
                    className="py-0 text-lg hover:bg-gray-600 hover:text-white"
                    href="/"
                  >
                    Facturation
                  </a> */}
                </li>
                <li>
                <Link to="/Agenda" className="py-0 text-lg hover:bg-gray-600 hover:text-white" >Agenda</Link>
                  {/* <a
                    className="py-0 text-lg hover:bg-gray-600 hover:text-white"
                    href="/"
                  >
                    Agenda
                  </a> */}
                </li>
                <li>
                  <a
                    className="py-0 text-lg hover:bg-gray-600 hover:text-white"
                    href="https://transcription.agestia.fr/?key=demokey"
                  >
                    Transcription
                  </a>
                </li>
              </ul>
            </div>
            <div className="py-0 hidden- -lg:flex navbar-end">
              <button onClick={handleShowDisconnect} className="-btn bg-red-200- ">
                {/* <LogOut height={32} width={32} /> */}                
                <svg fill="#000000" width="24px" height="24px" viewBox="-2 0 19 19" xmlns="http://www.w3.org/2000/svg" className="cf-icon-svg"><path d="M7.498 17.1a7.128 7.128 0 0 1-.98-.068 7.455 7.455 0 0 1-1.795-.483 7.26 7.26 0 0 1-3.028-2.332A7.188 7.188 0 0 1 .73 12.52a7.304 7.304 0 0 1 .972-7.128 7.221 7.221 0 0 1 1.387-1.385 1.03 1.03 0 0 1 1.247 1.638 5.176 5.176 0 0 0-.993.989 5.313 5.313 0 0 0-.678 1.181 5.23 5.23 0 0 0-.348 1.292 5.22 5.22 0 0 0 .326 2.653 5.139 5.139 0 0 0 .69 1.212 5.205 5.205 0 0 0 .992.996 5.257 5.257 0 0 0 1.178.677 5.37 5.37 0 0 0 1.297.35 5.075 5.075 0 0 0 1.332.008 5.406 5.406 0 0 0 1.32-.343 5.289 5.289 0 0 0 2.211-1.682 5.18 5.18 0 0 0 1.02-2.465 5.2 5.2 0 0 0 .01-1.336 5.315 5.315 0 0 0-.343-1.318 5.195 5.195 0 0 0-.695-1.222 5.134 5.134 0 0 0-.987-.989 1.03 1.03 0 1 1 1.24-1.643 7.186 7.186 0 0 1 1.384 1.386 7.259 7.259 0 0 1 .97 1.706 7.413 7.413 0 0 1 .473 1.827 7.296 7.296 0 0 1-4.522 7.65 7.476 7.476 0 0 1-1.825.471 7.203 7.203 0 0 1-.89.056zM7.5 9.613a1.03 1.03 0 0 1-1.03-1.029V2.522a1.03 1.03 0 0 1 2.06 0v6.062a1.03 1.03 0 0 1-1.03 1.03z"/></svg>
              </button>
            </div>


        </header>
      </div>

      <Drawer />

      
    </div>
    </div>

  );
};

// export default Head;
export { Head };
