import config from '../Config.json';
import { proxy, subscribe } from 'valtio'

// const _storeAuth = proxy({
//   users: [], 
//   current_user: {}, 
//   current_user_id: -1, 
//   isAuthenticated: false 
// });

// const _storeApp = proxy({
//   dossiers: [] ,
//   contacts: [],
// });

// export {_storeApp, _storeAuth};

const auth = {
  users: [], 
  current_user: {}, 
  current_user_id: -1, 
  isAuthenticated: false,
  isDeconnecting: false  
};

const app = {
  dossiers: [] ,
  contacts: [],
};

let _store = proxy( JSON.parse(localStorage.getItem('foo')) || {
  api: "Valtio",
  auth,
  app
});

// const unsubscribe = subscribe( _store, ()=> {
subscribe( _store, ()=> {
  config.DEBUG && console.log('- Store has changed');
  localStorage.setItem('foo', JSON.stringify(_store))
})

export { _store };