//import React, { useState, useEffect, useContext } from "react";
//import { AuthContext } from "./_Context.js";
import { useParams } from "react-router-dom";

const Contact = () => {
  // const [isLoggedIn, setIsLoggedIn ] = useState(false);
  // const { user, isAuthenticated } = useContext(AuthContext);
  const { id } = useParams();
  // const post = getPost(id);

  /*function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }*/

  /*useEffect(() => {
    // setIsLoggedIn( getCookie('agestia_logged') );
  }, []);*/

  return (
    <div className="flex h-screen place-content-center">
      <div className="m-auto">Fiche contact {id}</div>
    </div>
  );
};

export default Contact;
