import config from '../Config.json';
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { motion, AnimatePresence } from "motion/react";


// --------------------------------------------- [ ICONS ]
import {
  EuroSquare,
  Clock,
  Calendar,
  Mail,
  Home,
  Folder,
  Notes,
  UserCircle,
  Microphone,
  PathArrow,
  Book,
  MultiplePages
} from "iconoir-react"; // iconoir lib
import { Select, Breadcrumbs } from 'react-daisyui';

// --------------------------------------------- [ STORE ]
import { proxy, useSnapshot } from "valtio";
import { _store } from "./_Context.js"; // _store for write + store for reading

// --------------------------------------------- [ UTILS ]
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tools from "./Tools" ;


const Dossier = () => {
  // const [isLoggedIn, setIsLoggedIn ] = useState(false);
  // const { user, isAuthenticated } = useContext(AuthContext);
  const [value, setValue] = useState('default');
  const { id } = useParams();
  // const post = getPost(id);

  /*function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
      }
      return "";
      }*/
     
     /*useEffect(() => {
      // setIsLoggedIn( getCookie('agestia_logged') );
      }, []);*/
      
      return (
        <div className="flex flex-col -h-screen mt-16 p-2 place-content-center">

<AnimatePresence>
      <motion.div className="flex flex-col -w-full p-2 items-center justify-center gap-2 font-sans"   
        initial={{ opacity: 0, transform: "translateX(-30px)" }}
        animate={{ opacity: 1, transform: "translateX(0px)" }}
        transition={{type: "easeOut", duration: 0.5}}
        exit={{ opacity: 0, scale: 0 }}
        // style={box}
        key="box">
      <Breadcrumbs >
          <Breadcrumbs.Item href="/"><Home className="w-4 h-4 mr-2" /> Dossiers </Breadcrumbs.Item>
          <Breadcrumbs.Item > <Folder className="w-4 h-4 mr-2" />Fiche</Breadcrumbs.Item>
        </Breadcrumbs>
        <Select className="w-1/2" size="sm" value={value} onChange={event => setValue(event.target.value)}>
          {/* <Option value={'default'} disabled>
            Pick your favorite Simpson
            </Option> */}
          <option value={'fiche'}>Fiche</option>
          <option value={'docs'}>Documents</option>
          <option value={'histo'}>Historique</option>
          <option value={'temps'}>Temps</option>
          <option value={'taches'}>Tâches</option>
        </Select>
            
        <div className="m-auto">Fiche dossier {id}</div>


      
      <div className="flex flex-col w-full p-2 items-center justify-center gap-2 font-sans">
        <ul className="menu menu-horizontal flex -w-full justify-center bg-base-200 rounded-box mt-6">
          <li>
            <a className="tooltip" data-tip="Fiche">
              <Home />
            </a>
          </li>
          <li>
            <a className="tooltip" data-tip="Documents">
              <MultiplePages />
            </a>
          </li>
          <li>
            <a className="tooltip" data-tip="Historique">
              <PathArrow />
            </a>
          </li>
          <li>
            <a className="tooltip" data-tip="Temps">
              <Clock />
            </a>
          </li>          
          <li>
            <a className="tooltip" data-tip="Tâches">
              <Notes />
            </a>
          </li>           
        </ul>
      </div>
      </motion.div>
</AnimatePresence>    

    </div>
  );
};

export default Dossier;
